<template>
  <div class="page">
    <button @click="clk" class="btn">测试获取</button>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods:{
    clk(){
      window.open("https://mall.iotrui.com/BuyCard_new/test/index_new81_test.html?sp=596f713f9a7376fe90a62abaaedecc2d")
    }
  }
}
</script>
<style lang="less" scoped>
.page{
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  padding-top: 20px;
  .btn{
  background-color: aqua;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
  display: block;
  }
}
</style>